header.mo {
    display: flex;
    flex-direction: column;
    position: relative;
    height: max-content!important;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom:0px!important;
}

.mo .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: auto;
    height: 100vh!important;
    flex-wrap: wrap;
    margin-top: 40px!important;
}

header.mo .header-right {
    text-align: center;
    width: 50%;
    margin-right: 30px;;
    display: flex;
    flex-direction: column;
    gap:px;
    text-align: center;
    font-weight: 900!important;
    margin-top: 0px;
}
header.mo .header-right h3{
    font-size:40px!important;
    width: 100%;
    line-height: 1.8;
}

header.mo .header-right .math,
header.mo .header-right .salah,
header.mo .header-right .secandry,
header.mo .header-right .start-name {
    color: #9900CC !important;
}




header.mo .header-right .name {
    background-image: url('../../Components//imges/text-back.png');
    background-size:90% 100%;
    background-repeat: no-repeat;
    width: 100%;
    font-size:50px!important;
}

header.mo .header-right .name .background_name {
    width: max-content;
    height: 100%;
    background-color: #B1DAE7;
    clip-path: polygon(3% 0, 82% 0, 100% 68%, 19% 66%);
}
header.mo .header-left {
    width: 35%;
    margin-left: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -30px;
}
header.mo .header-left .header_img {
    background-image: url('../imges/headerimg.png')!important;
    background-size: 100%!important;
    width: 100%!important;
}