.departments{
    background-image: url('../imges/back.png');
    background-size: cover;
    background-color:#8503D0 ;
    padding-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom:5px;
    padding:20px 0px;
    overflow-x: hidden!important;
    width: 100%;
}
.departs{
    display: flex;
    justify-content: space-around;
    align-items: center;
}.departs .mark-img{
    width: 60%;
    height:3px;
    margin: auto;
    border-radius: 10px;
    background-color: #8503D0;
}
.depart{
    background-color: rgb(255, 255, 255);
    width:32%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.depart:hover{
    transition:0.5s;
    transform: scale(1.03)!important;
}
.departments .depart .img{
    background-image:url('../imges/math-sort-feature.jpg');
    background-size: cover;
    display: flex;
    border-radius: 10px;

}
.departments .sc .img{
    background-image:url('../imges/back2.jpg');
    background-size: cover;
    display: flex;
    border-radius: 10px;

}
.depart img {
    width: 100%;
    margin: auto;
}
.depart .mark{
    width: 30%;
    height:6px;
    margin: 20px auto;
    border-radius: 20px;
    background-color: #ffffff;
}
.mark2{
    width:20%;
    height:6px;
    margin: 20px auto;
    border-radius: 20px;
    background-color: #ffffff;
}
.departments .address{
    color: white;
    font-size: 40px;
}