.properties{
    background-color:#8503D0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    overflow-y: hidden;
}
.properties .address{
    margin-top: 20px;
    color: #ffffff;
    font-size: 20px;
} .properties .mark{
    width: 30%;
    height:6px;
    margin: 20px auto;
    border-radius: 20px;
    background-color: #ffffff;
}
 .properties .mark2{
    width:20%;
    height:6px;
    margin: 20px auto;
    border-radius: 20px;
    background-color: #ffffff;
}
.pros{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px;
}
.pros .pro {
    width: 30% ;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    transition: 0.5s;
    background-color: #fff7f7;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.pros .pro img{
    width: 100%;
}
.pros .pro .pro-address{
    width: 95%;
    margin: auto;
    background-color:#8503D0;
    border-radius:5px;
    padding: 30px 0px;
    text-align: center;
    position: absolute;
    color: white;
    top:-30px;
    /* transform: rotate(20deg); */
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
    transition:1s;
    font-size:12px!important;
    animation-name: pro;
    animation-delay: 4s;
    animation-duration:6s;
    animation-iteration-count: infinite;
}
@keyframes pro {
    0%{
        top: 10px;
    }
    50%{
        top:65%;
    }
    100%{
        top: 10px;
    }
}